<div class="loadWrapper">
  <div class="loader loader1">
    <div>
      <div>
        <div>
          <div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>Loading, Please wait!</div>
</div>
